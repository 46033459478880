import React, { useState } from "react";
import sum from "lodash/sum";
import { withTranslation } from "react-i18next";

import { NutValue, Seo, Toggle, Badge } from "../../components";
import { SERVER } from "../../constants/config";
import { TYPE_DISHES, MOBILE_WIDTH } from "../../constants/data";
import { getAllMenu } from "../../helpers/item";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import styles from "./styles.module.scss";

const ComboView = ({ t, product, categories, addDishToCart }) => {
  const boards =
    categories.length > 1 &&
    categories[0].dishes[1].pizza.boards.map((item) => item.name);

  let dishesArray = [...product.dishes];
  dishesArray.fill(0);
  const [visibleBoard, setVisibleBoard] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [activeDish, setActiveDish] = useState(activeItem);
  const [comboDishes, setComboDishes] = useState(dishesArray);
  const [changeDish, setChangeDish] = useState(null);
  const menu = getAllMenu(categories);
  const [board, setBoard] = useState(
    product.dishes.map(() => (boards ? boards[0] : null))
  );

  const { width } = useWindowDimensions();
  const mobileView = width < MOBILE_WIDTH;

  const change = (index) => {
    if (visibleBoard && activeItem === index) {
      setVisibleBoard(!visibleBoard);
      setActiveItem(null);
    } else if (visibleBoard) {
      setActiveItem(index);
      setActiveItem(index);
    } else {
      setVisibleBoard(!visibleBoard);
      setActiveItem(index);
      setActiveDish(index);
    }
  };
  const productWithData = product.dishes.map((set) => {
    const newSet = set.dishes.map((dish) => {
      const newDish = menu.filter((item) => item.id === dish.id && item);
      return newDish[0];
    });
    return { dishes: newSet };
  });

  let updateProduct = {
    ...product,
    dishes: productWithData,
  };

  const renderCombo = () => {
    return (
      <>
        <Seo
          t={t}
          path="combo"
          title={product.seo && product.seo.title}
          keywords={product.seo && product.seo.keywords}
          description={product.seo && product.seo.description}
          id={product.id}
        />
        {updateProduct.dishes.map((item, index) => {
          const isPizza = item.dishes[comboDishes[index]]?.pizza;
          return (
            <div
              className={
                activeItem === index ? styles.comboCardActive : styles.comboCard
              }
              key={index}
              onClick={() => {
                setChangeDish(null);
                change(index);
              }}
            >
              <div className={styles.infoWrapper}>
                <div className={styles.imageContainer}>
                  <img
                    className={styles.comboImage}
                    alt={product.name}
                    src={
                      SERVER +
                      item.dishes[comboDishes[index]]?.image[
                        board[index] !== boards[0] ? 1 : 0
                      ].url
                    }
                  />
                </div>

                <div className={styles.comboInfo}>
                  <h3 className={styles.comboName}>
                    {item.dishes[comboDishes[index]]?.name}
                  </h3>
                  <p className={styles.comboDescription}>
                    {item.dishes[comboDishes[index]]?.short_description}
                  </p>
                  <div className={styles.cardFooter}>
                    {isPizza && isPizza.active && (
                      <span className={styles.sideChoise}>
                        {t("pages.combo.board")}
                        <br />
                        {board[index]}
                      </span>
                    )}

                    {activeItem !== index && (
                      <button className={styles.button} type="button">
                        {t("pages.combo.change")}
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.pizzaSide}>
                {isPizza && isPizza.active && (
                  <Toggle
                    items={boards}
                    activeItem={board}
                    setActiveItem={setBoard}
                    activeIndex={index}
                  />
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  let boardPrice = [];
  board.forEach((item) => {
    categories[0].dishes[1].pizza.boards.forEach((board) => {
      if (board.name === item) {
        boardPrice.push(board.price);
      }
    });
  });

  const onPressBuyCombo = () => {
    const dishesString = product.dishes
      .map((item, index) => {
        const currentDish = menu.filter((menuDish) => {
          return menuDish.id === item.dishes[comboDishes[index]].id && menuDish;
        });

        if (currentDish[0] && currentDish[0].pizza.active) {
          return currentDish[0].name + ` (${board[index]})`;
        } else {
          return currentDish[0].name;
        }
      })
      .join(", ");

    const purchaseCombo = {
      type: TYPE_DISHES.combo,
      id: product.id,
      name: product.name,
      image: SERVER + product.image[0].url,
      initialPrice: product.percent
        ? sumAmountWithPercent() + sum(boardPrice)
        : product.price + sum(boardPrice),
      price: product.percent
        ? sumAmountWithPercent() + sum(boardPrice)
        : product.price + sum(boardPrice),
      count: 1,
      description: dishesString,
    };
    addDishToCart(purchaseCombo);
  };
  const nutProduct = product.dishes.map((item, index) => {
    const filterDish = menu.filter((menuDish) => {
      return menuDish.id === item.dishes[comboDishes[index]].id && menuDish;
    });
    return { ...filterDish[0] };
  });

  const sumAmountWithPercent = () => {
    let result = product.dishes.map((set, index) => {
      const price = set.dishes[comboDishes[index]].price;
      const saleSum = (price / 100) * product.percent;
      return price - Math.round(saleSum);
    });
    return sum(result);
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftBlock}>
        <h3 className={styles.leftBlockTitle}>{t("pages.combo.choseCombo")}</h3>
        {visibleBoard ? (
          <div
            className={changeDish && mobileView ? styles.hide : styles.dishes}
          >
            {product.dishes[!activeItem ? 0 : activeItem].dishes.map(
              (item, index) => {
                return (
                  <div
                    key={index}
                    className={styles.dish}
                    onClick={() => {
                      setActiveDish(index);
                      setChangeDish(true);
                      setComboDishes((oldArray) => {
                        const newArray = [...oldArray];
                        newArray[activeItem] = index;
                        return newArray;
                      });
                    }}
                  >
                    <img
                      className={
                        activeDish === index
                          ? styles.imagesActive
                          : styles.images
                      }
                      src={SERVER + item.image[0].url}
                      alt={product.name}
                    />

                    <p className={styles.nameleft}>{item.name}</p>
                    <p className={styles.price}>
                      {item.price}
                      {t("currency")}
                    </p>
                  </div>
                );
              }
            )}
          </div>
        ) : (
          <div className={styles.info}>
            <div className={styles.badgeWrapper}>
              <Badge data={true} />
            </div>
            <img
              className={styles.image}
              src={SERVER + product.image[0].url}
              alt={product.name}
            />
          </div>
        )}
      </div>
      <div
        className={
          visibleBoard && mobileView && !changeDish
            ? styles.hide
            : styles.rightBlock
        }
      >
        <div className={styles.cover}>
          <div className={styles.infoBlock}>
            <div className={styles.title}>
              <h2>{product.name}</h2>
              <NutValue t={t} product={nutProduct} />
            </div>
            <p className={styles.description}>{product.short_description}</p>
            {renderCombo()}
          </div>
        </div>
        <div className={styles.buttonFooter}>
          <button
            className={styles.addtoCart}
            type="button"
            onClick={onPressBuyCombo}
          >
            {`${t("pages.combo.addToCart")} ${
              product.percent
                ? sumAmountWithPercent() + sum(boardPrice)
                : product.price + sum(boardPrice)
            } ${t("currency")}`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ComboView);
