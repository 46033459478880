import React from "react";
import CryptoJS from "crypto-js";
import { withTranslation } from "react-i18next";

import {
  LIQPAY_PUBLIC_KEY,
  LIQPAY_PRIVATE_KEY,
  LIQPAY_RESULT_URL,
  LIQPAY_SERVER_URL,
  LIQPAY_SERVER,
} from "../../../../constants/config";

import styles from "./styles.module.scss";

const utf8_to_b64 = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)));
};

const LiqPayPay = ({ t, id, amount }) => {
  console.log(id);
  const jsonString = {
    version: "3",
    public_key: LIQPAY_PUBLIC_KEY,
    private_key: LIQPAY_PRIVATE_KEY,
    action: "pay",
    amount: amount,
    currency: "UAH",
    description: `BellaMozzarella, ...${id}`,
    result_url: LIQPAY_RESULT_URL,
    server_url: LIQPAY_SERVER_URL,
    order_id: id,
  };

  const data = utf8_to_b64(JSON.stringify(jsonString));
  const signString = LIQPAY_PRIVATE_KEY + data + LIQPAY_PRIVATE_KEY;
  const hash = CryptoJS.SHA1(signString);
  const signature = CryptoJS.enc.Base64.stringify(hash);
  return (
    <form method="POST" action={LIQPAY_SERVER} acceptCharset="utf-8">
      <input type="hidden" name="data" value={data} />
      <input type="hidden" name="signature" value={signature} />
      {id && (
        <button className={styles.button}>
          {t("pages.cart.components.liqpay.goToPay")}
        </button>
      )}
    </form>
  );
};

export default withTranslation()(LiqPayPay);
