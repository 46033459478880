import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link, Outlet } from "react-router-dom";
import { useSpring, animated as a } from "react-spring";
import { HiOutlineChevronDown, HiOutlineX } from "react-icons/hi";

import { MOBILE_WIDTH } from "../../constants/data";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useScrollBlock from "../../hooks/useScrollBlock";
import styles from "./styles.module.scss";

const Drawer = ({ children }) => {
  const [reverse, setReverse] = useState(false);
  const modalEl = useRef(null);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    blockScroll();
    return () => {
      allowScroll();
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalEl.current && !modalEl.current.contains(event.target)) {
        navigate(-1);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalEl]);

  const mobileView = width < MOBILE_WIDTH;

  const propsContainerDrawer = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: "300" },
    reverse: reverse,
  });

  const propsContantDrawer = useSpring({
    from: { opacity: 0, transform: "translateY(100%)" },
    to: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: "translateY(100%)" },
    config: { duration: "500" },
    reverse: reverse,
  });

  const propsContainerModal = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: "500" },
    reverse: reverse,
  });

  const propsContantModal = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: "500" },
    reverse: reverse,
  });

  return (
    <a.div
      style={mobileView ? propsContainerDrawer : propsContainerModal}
      className={mobileView ? styles.container : styles.wrapper}
    >
      <a.div
        style={mobileView ? propsContantDrawer : propsContantModal}
        ref={modalEl}
        className={styles.contant}
      >
        <div
          className={styles.dawn}
          onClick={() => {
            setReverse(true);
            setTimeout(navigate(-1), 700);
          }}
        >
          <HiOutlineChevronDown />
        </div>
        <Link
          to={{}}
          onClick={() => {
            navigate(-1);
          }}
          className={styles.link}
        >
          <HiOutlineX className={styles.close} />
        </Link>
        <div className={styles.contant}>{children}</div>
      </a.div>
      <Outlet />
    </a.div>
  );
};

export default Drawer;
