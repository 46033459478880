import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { withTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

import { sortByPosition, sumQuantity } from "../../helpers/item";
import { currentLang } from "../../helpers/language";
import { CartBox } from "../index";
import Cart from "../../pages/Cart";
import assets from "../../assets";

import styles from "./styles.module.scss";

const DEFAULT_SCROlL_MOBILE = 440;
const DEFAULT_SCROlL_DESKTOP = 105;

const Navigation = ({ cart, categories = [] }) => {
  const [visibleCart, isVisibleCart] = useState(false);
  const [scroll, changeScroll] = useState(0);

  const currentLanguage = currentLang();

  window.addEventListener("scroll", () => changeScroll(pageYOffset));

  const orders = useSelector((state) => state.orders.data, shallowEqual);
  const count = sumQuantity(orders);

  // Menu list to show on other pages
  let nav = sortByPosition(categories).map((category) => category.name);

  // Get anchor positions for menu
  const anchors = document.querySelectorAll('[data="nav"]');
  let anchorPositions = [];
  for (let i = 0; i < anchors.length; ++i) {
    anchorPositions.push(anchors[i].offsetTop);
  }

  const checkActiveLink = (index) => {
    const height =
      anchorPositions.length === index + 1
        ? anchorPositions[index]
        : anchorPositions[index + 1] - anchorPositions[index];
    return (
      scroll + 5 > anchorPositions[index] &&
      scroll < anchorPositions[index] + height - 5
    );
  };

  return (
    <div
      className={`${styles.container} ${
        cart ? styles.desktop : styles.mobile
      } ${
        scroll > (cart ? DEFAULT_SCROlL_DESKTOP : DEFAULT_SCROlL_MOBILE)
          ? styles.containerFixed
          : ""
      } `}
    >
      <Link
        to={`/#`}
        className={`${styles.miniLogo} ${
          scroll > (cart ? DEFAULT_SCROlL_DESKTOP : DEFAULT_SCROlL_MOBILE)
            ? styles.animation
            : ""
        }`}
      >
        <img src={assets.IMAGES.LOGO_MINI2X} />
      </Link>
      <nav className={styles.nav}>
        {nav.length > 2 &&
          nav.map((item, index) => {
            return (
              <li key={index} className={styles.item}>
                <Link
                  className={
                    checkActiveLink(index) ? styles.activeLink : styles.link
                  }
                  to={`/${currentLanguage}/#${item}`}
                  smooth
                >
                  {item}
                </Link>
              </li>
            );
          })}
      </nav>

      <CartBox
        visibleCart={visibleCart}
        isVisibleCart={isVisibleCart}
        count={count}
      />

      <Cart visibleCart={visibleCart} isVisibleCart={isVisibleCart} />
    </div>
  );
};

export default withTranslation()(Navigation);
