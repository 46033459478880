import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import { getCategories } from "../../store/redux/categories";
import { getCombos } from "../../store/redux/combos";
import { getStories } from "../../store/redux/stories";
import { getSettings } from "../../store/redux/settings";
import { addOrder } from "../../store/redux/orders";
import { sortByPosition } from "../../helpers/item";
import MainView from "./MainView";

const Main = ({ t }) => {
  const dispatch = useDispatch();
  const combos = useSelector((state) => state.combos.data, shallowEqual);
  const stories = useSelector((state) => state.stories.data, shallowEqual);
  const categories = useSelector(
    (state) => state.categories.data,
    shallowEqual
  );
  const settings = useSelector((state) => state.settings.data, shallowEqual);

  // Add new category
  let categoriesWithCombos = [...categories];

  categoriesWithCombos = categoriesWithCombos.filter((category) => {
    return category.visible && category;
  });

  categoriesWithCombos.splice(1, 0, {
    name: t("pages.main.components.combo.title"),
    position: 2,
    dishes: combos,
    visible: true,
  });

  const nav = sortByPosition(categoriesWithCombos).map(
    (category) => category.name
  );

  useEffect(() => {
    dispatch(getStories());
    dispatch(getCombos());
    dispatch(getCategories());
    dispatch(getSettings());
  }, [t, dispatch]);

  const addDishToCart = (data) => {
    dispatch(addOrder(data));
  };

  return (
    <MainView
      stories={stories}
      combos={combos}
      categories={categoriesWithCombos}
      nav={nav}
      settings={settings}
      addDishToCart={addDishToCart}
    />
  );
};

export default withTranslation()(Main);
