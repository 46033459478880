import React, { useState, useEffect } from "react";
import Stories from "react-insta-stories";
import { HiOutlineX } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { withTranslation } from "react-i18next";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import useScrollBlock from "../../hooks/useScrollBlock";
import { MOBILE_WIDTH } from "../../constants/data";
import { SERVER } from "../../constants/config";
import { sortByPosition } from "../../helpers/item";

import styles from "./styles.module.scss";

const KEY_STORIES = "stories";

const StorySlider = ({ t, data }) => {
  const { width, height } = useWindowDimensions();
  const [activeStory, setActiveStory] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);

  const viewedStories = JSON.parse(localStorage.getItem(KEY_STORIES));

  const addViewedStory = (id) => {
    if (!viewedStories) {
      const data = [id];
      localStorage.setItem(KEY_STORIES, JSON.stringify(data));
    }
    if (viewedStories?.indexOf(id) === -1) {
      const data = [...viewedStories, id];
      localStorage.setItem(KEY_STORIES, JSON.stringify(data));
    }
  };

  const [blockScroll, allowScroll] = useScrollBlock();

  const sortedData = sortByPosition(data);

  useEffect(() => {
    if (activeStory > 0) {
      window.scrollTo(0, 0);
      blockScroll();
    }
    return () => {
      allowScroll();
    };
  }, [activeStory]);

  const resize_width = width > MOBILE_WIDTH ? 350 : width;
  const resize_height = width > MOBILE_WIDTH ? 650 : height + 5;

  const handlerCloseStory = () => {
    setActiveStory(0);
    setActiveSlide(0);
  };

  const config = {
    delta: 10,
    preventScrollOnSwipe: false,
    trackTouch: true,
    trackMouse: false,
    rotationAngle: 0,
    swipeDuration: Infinity,
    touchEventOptions: { passive: true },
  };

  const handlers = useSwipeable({
    onSwipedDown: () => {
      handlerCloseStory();
    },
    ...config,
  });

  const handleBack = () => {
    if (activeSlide + 1 === 1 && activeStory === 1) {
      setActiveStory(0);
      setActiveSlide(0);
    } else if (activeSlide + 1 === 1) {
      setActiveStory(activeStory - 1);
      setActiveSlide(0);
    } else {
      setActiveSlide(activeSlide - 1);
    }
  };

  const handleNext = () => {
    if (
      activeSlide + 1 === sortedData[activeStory - 1]?.contant.length &&
      activeStory === sortedData.length
    ) {
      setActiveStory(1);
      setActiveSlide(0);
    } else if (
      activeSlide + 1 ===
      sortedData[activeStory - 1]?.contant.length
    ) {
      setActiveStory(activeStory + 1);
      setActiveSlide(0);
    } else {
      setActiveSlide(activeSlide + 1);
    }
  };

  const storiesSet = sortedData[activeStory - 1]?.contant.map((item, index) => {
    const path = sortedData[activeStory - 1]?.combo
      ? `combo/${sortedData[activeStory - 1].combo.id}`
      : data[activeStory - 1].dish
      ? `dish/${sortedData[activeStory - 1].dish.id}`
      : "";

    return {
      url: `${SERVER}${item.url}`,
      seeMoreCollapsed: () => {
        if (
          sortedData[activeStory - 1].contant.length === index + 1 &&
          path.length > 0
        ) {
          return (
            <div className={styles.wrapperLink} onClick={handlerCloseStory}>
              <Link className={styles.link} to={`${path}`}>
                {t("pages.main.components.stories.link")}
              </Link>
            </div>
          );
        }
      },
      seeMore: () => {
        return null;
      },
    };
  });

  const StoriesView = () => {
    if (activeStory > 0) {
      return (
        <div className={styles.wrapperStories}>
          <div className={styles.close} onClick={() => setActiveStory(0)}>
            <HiOutlineX className={styles.close} />
          </div>
          <div className={styles.back} {...handlers} onClick={handleBack} />
          <div className={styles.next} {...handlers} onClick={handleNext} />
          <Stories
            currentIndex={activeSlide}
            stories={storiesSet}
            defaultInterval={15000}
            width={resize_width}
            height={resize_height}
            keyboardNavigation
            onStoryEnd={() => {
              setActiveSlide((activeSlide) => activeSlide + 1);
            }}
            onStoryStart={() => {
              addViewedStory(sortedData[activeStory - 1].id);
              setActiveSlide((activeSlide) => activeSlide + 1 - 1);
            }}
            onAllStoriesEnd={() => {
              if (activeStory < sortedData.length) {
                setActiveStory(activeStory + 1);
                setActiveSlide(0);
              } else {
                setActiveStory(1);
                setActiveSlide(0);
              }
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <StoriesView />
      <div className={styles.preview}>
        <div className={styles.emptyStory} />
        {sortedData.map((item, index) => {
          return (
            <div key={index} onClick={() => setActiveStory(index + 1)}>
              <img
                className={
                  !viewedStories || viewedStories?.indexOf(item.id) === -1
                    ? styles.previewImageNew
                    : styles.previewImage
                }
                src={SERVER + item.preview[0].url}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withTranslation()(StorySlider);
