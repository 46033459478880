import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import {
  Seo,
  Hint,
  Shutdown,
  Navigation,
  Stories,
  Combos,
  Categories,
  Description,
} from "../../components";
import { sortByPosition } from "../../helpers/item";

import styles from "./styles.module.scss";

const CategoryView = ({
  stories,
  combos,
  categories,
  settings,
  addDishToCart,
  allCategories,
}) => {
  const path = useLocation();
  const isItem =
    path.pathname.includes("dish") || path.pathname.includes("combo");
  const pathname = window.location.pathname;
  const id = pathname.slice(pathname.lastIndexOf("/") + 1);
  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };
  // Remove unnecessary category
  const categoriesWithCombos = allCategories.filter((category) => {
    if (isNumeric(id)) {
      return category.id === parseInt(id) && category;
    } else {
      return category?.seo?.url === id && category;
    }
  });
  const nav = sortByPosition(categoriesWithCombos).map(
    (category) => category.name
  );

  const description =
    categoriesWithCombos.length === 1 &&
    categoriesWithCombos[0].long_description;
  return (
    <div className={styles.container}>
      <Navigation cart categories={categories} />
      <Seo
        path={"category"}
        title={categoriesWithCombos[0]?.seo?.title}
        keywords={categoriesWithCombos[0]?.seo?.keywords}
        description={categoriesWithCombos[0]?.seo?.description}
        url={categoriesWithCombos[0]?.seo?.url}
      />
      <Shutdown data={settings} />
      <Stories data={stories} />
      <Combos data={combos} path={"category"} />
      <Navigation categories={categories} />
      <Categories
        data={categoriesWithCombos}
        anchors={nav}
        addDishToCart={addDishToCart}
        path={"category"}
      />
      {!isItem && <Description text={description} />}
      <Hint />
      <Outlet />
    </div>
  );
};

export default CategoryView;
